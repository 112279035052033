import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";

// import searchIcon from "../../../../assets/search_icon.svg";

const CustomToolbar = props => {
  const {
    isMobileOrTablet,
    filters,
    users,
    clients,
    setFilters,
    setPage
  } = props;

  const [isFiltered, setIsFiltered] = useState(false);
  // const searchInput = useRef(null);

  const requestSearch = searched => {
    // const searchString = searched.toLowerCase();
    // filters.current = {
    //   ...filters.current,
    //   search: searchString
    // };
    // applyFilters();
  };

  const handleUserChange = event => {
    setPage(0);
    const user = users.find(user => user.PersonalNumber === event.target.value) || null;
    setFilters({
      ...filters,
      user
    });
    setIsFiltered((user || filters.client || filters.search));
  };

  const handleClientChange = event => {
    setPage(0);
    const client = clients.find(client => client.Ssn === event.target.value) || null;
    setFilters({
      ...filters,
      client
    });
    setIsFiltered((client || filters.user || filters.search));
  };

  const resetFilters = () => {
    setPage(0);
    setFilters({
      user: null,
      client: null
    });
    setIsFiltered(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobileOrTablet ? "column" : "row",
          width: "100%",
          my: 1
        }}
      >
        <Box
          sx={{
            width: isMobileOrTablet ? "100%" : "auto",
            display: "flex",
            justifyContent: "left",
            mt: isMobileOrTablet ? 1.5 : 0
          }}
        >
          <FormControl
            sx={{
              width: isMobileOrTablet ? "100%" : 150,
              mr: isMobileOrTablet ? 0 : 1
            }}
          >
            <InputLabel id="user-type-select-label" size="small">
              Användare
            </InputLabel>
            <Select
              size="small"
              labelId="user-type-select-label"
              id="user-type-select"
              value={filters.user?.PersonalNumber || ""}
              label="Användare"
              onChange={handleUserChange}
              sx={{ height: 40 }}
              // MenuProps={
              //   {
              //     // style: { maxHeight: 500, position: "absolute" }
              //     // disableScrollLock: true
              //     // autoFocus: false,
              //     // disableAutoFocus: true
              //   }
              // }
            >
              <MenuItem value="" sx={{ alignItems: "flex-start" }}>
                Alla
              </MenuItem>
              {users.map(
                (user, key) =>
                  user && (
                    <MenuItem
                      key={key}
                      value={user.PersonalNumber}
                      sx={{ alignItems: "flex-start" }}
                    >
                      {user.FirstName + " " + user.LastName}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: isMobileOrTablet ? "100%" : "auto",
            display: "flex",
            justifyContent: "left",
            mt: isMobileOrTablet ? 1.5 : 0
          }}
        >
          <FormControl
            sx={{
              width: isMobileOrTablet ? "100%" : 150,
              mr: isMobileOrTablet ? 0 : 1
            }}
          >
            <InputLabel id="user-type-select-label" size="small">
              Kunder
            </InputLabel>
            <Select
              size="small"
              labelId="user-type-select-label"
              id="user-type-select"
              value={filters.client?.Ssn || ""}
              label="Kunder"
              onChange={handleClientChange}
              sx={{ height: 40 }}
              // MenuProps={
              //   {
              //     // style: { maxHeight: 500, position: "absolute" }
              //     // disableScrollLock: true
              //     // autoFocus: false,
              //     // disableAutoFocus: true
              //   }
              // }
            >
              <MenuItem value="" sx={{ alignItems: "flex-start" }}>
                Alla
              </MenuItem>
              {clients.map(
                (client, key) =>
                  client && (
                    <MenuItem
                      key={key}
                      value={client.Ssn}
                      sx={{ alignItems: "flex-start" }}
                    >
                      {client.Name || (client.FirstName + " " + client.LastName)}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </Box>
        {/* <Box
          sx={{
            width: isMobileOrTablet ? "100%" : "auto",
            display: "flex",
            flexGrow: 1,
            justifyContent: isMobileOrTablet ? "left" : "right",
            mt: isMobileOrTablet ? 1.5 : 0
          }}
        >
          <TextField
            size="small"
            placeholder="Sök, fritext"
            type="search"
            onInput={e => requestSearch(e.target.value)}
            sx={{
              ...(isMobileOrTablet && { flexGrow: 2 })
            }}
            InputProps={{
              inputRef: searchInput,
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {
                borderRadius: "20px",
                fontSize: "0.8rem",
                height: 40
              }
            }}
          />
        </Box> */}
      </Box>
      <Box sx={{ width: 90 }}>
        {isFiltered && (
          <Link
            onClick={resetFilters}
            sx={{ display: "block", mt: 0, mr: 0, mb: 2.5, ml: 0.625 }}
          >
            <Typography variant="body3" align="left" sx={{ cursor: "pointer" }}>
              Nollställ filter
            </Typography>
          </Link>
        )}
      </Box>
    </>
  );
};

CustomToolbar.propTypes = {
  isMobileOrTablet: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired
};

export default CustomToolbar;
