export default function FormHelperText(theme) {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: 1.2,
        }
      }
    }
  };
}
