import { useState, useCallback, useReducer, useEffect } from "react";

import api from "@helpers/api";

import addUserModalReducer, { initialState } from "./addUserModalReducer";
import validateFields from "@helpers/formValidator";

const useAddUserModal = props => {
  const {
    isSelf,
    showCustomerNumber,
    showFuturKod,
    user,
    roles,
    resellers,
    updateUsers,
    toggleModal
  } = props;

  const [state, dispatch] = useReducer(addUserModalReducer, {
    ...initialState
  });

  const hasId = user && user.personal_number;
  const canSubmitForm = !!(
    state.firstName &&
    state.lastName &&
    state.roleId &&
    state.email
  );

  const toggle = () => {
    dispatch({
      type: "reset"
    });
    return toggleModal();
  };

  const isValidInput = (fieldName, value) => {
    if (
      fieldName === "roleId" ||
      fieldName === "resellerId" ||
      fieldName === "futurCode"
    ) {
      return value > 0;
    }

    return (
      value.length > 0 && (!state.inputErrors || !state?.inputErrors[fieldName])
    );
  };

  const handleInputChange = async e => {
    e.preventDefault();
    const key = e.target.name;
    let value = e.target.value;

    dispatch({
      type: "handleInput",
      field: key,
      payload: value
    });

    const formData = {
      ...state,
      [key]: value
    };

    const fieldsToValidate = { ...formData };
    // Exclude field that don't require validation
    delete fieldsToValidate.phoneHome;
    delete fieldsToValidate.phoneMobile;
    delete fieldsToValidate.customerNumber;

    let inputErrors = validateFields(fieldsToValidate);

    return dispatch({
      type: "handleInputErrors",
      payload: inputErrors || {}
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    dispatch({
      type: "handleServerError",
      payload: null
    });

    const {
      action,
      personalNumber,
      roleId,
      firstName,
      lastName,
      email,
      phoneHome,
      phoneMobile,
      customerNumber,
      resellerId,
      futurCode
    } = state;

    const formData = {
      personalNumber,
      roleId,
      firstName,
      lastName,
      email,
      phoneHome,
      phoneMobile,
      customerNumber: showCustomerNumber ? customerNumber : null,
      futurCode: showFuturKod ? parseInt(futurCode, 10) : null,
      resellerId: parseInt(resellerId, 10)
    };

    dispatch({
      type: "fetching",
      payload: true
    });

    try {
      const user = await api(`/partner/user/${action}`, formData);
      updateUsers(user);
      toggle();
    } catch (err) {
      let errorMessage = err?.message || "Unknown error.";
      if (err?.message === "USER_ALREADY_EXISTS") {
        errorMessage = "En användare med det personnumret existerar redan.";
      }

      return dispatch({
        type: "handleServerError",
        payload: errorMessage || "Unknown error."
      });
    }
  };

  useEffect(() => {
    if (user) {
      dispatch({
        type: "setUser",
        payload: user
      });
    }
  }, [user]);

  return {
    isSelf,
    showCustomerNumber,
    showFuturKod,
    state,
    hasId,
    roles,
    resellers,
    canSubmitForm,
    toggle,
    isValidInput,
    handleInputChange,
    handleSubmit
  };
};

export default useAddUserModal;
