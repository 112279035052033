import React from "react";
import PropTypes from "prop-types";
import api from "@helpers/api";

import { Modal as MuiModal, Box, Typography } from "@mui/material";

// import Button from "@components/Button/Button";
import ModalContentBox from "@mui-theme/styled/ModalContentBox";
import CloseModalBox from "@mui-theme/styled/CloseModalBox";
import ThemedButton from "@mui-theme/styled/ThemedButton";

const UserStatusModal = props => {
  const { user, updateUsers, toggleModal } = props;

  const setUserStatus = async status => {
    await api(`partner/user/setStatus`, {
      personalNumber: user.personal_number,
      isActive: status === "active"
    });

    updateUsers({ ...user, is_active: status === "active" ? "1" : "0" });
    toggleModal();
  };

  return (
    <MuiModal open={true}>
      <ModalContentBox
        sx={{
          width: 380
        }}
      >
        <Box
          sx={{
            width: "100%",
            px: 2,
            alignItems: "flex-start"
          }}
        >
          <Typography variant="h4" color="common.black">
            Ändra användarens aktiva status
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            p: 2,
            alignItems: "flex-start"
          }}
        >
          <Typography variant="subtitle1" color="common.black" align="left">
            Är du säker på att du vill aktivera/avaktivera denna användare
          </Typography>
        </Box>
        <CloseModalBox
          sx={{
            px: 2
          }}
        >
          <ThemedButton
            variant="outlined"
            onClick={toggleModal}
            sx={{
              minWidth: "unset",
              width: 100
            }}
          >
            Avbryt
          </ThemedButton>
          <ThemedButton
            variant="contained"
            onClick={() =>
              setUserStatus(user.is_active === "1" ? "inactive" : "active")
            }
            sx={{
              minWidth: "unset",
              width: 150
            }}
          >
            {user.is_active === "1" ? "Inaktivera" : "Aktivera"}
          </ThemedButton>
        </CloseModalBox>
      </ModalContentBox>
    </MuiModal>
  );
};

UserStatusModal.propTypes = {
  user: PropTypes.object.isRequired,
  updateUsers: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default UserStatusModal;
