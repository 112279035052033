import React from "react";

import AllActivityTable from "./AllActivityTable";

const UserActivityView = props => {  
  return (
    <>
      <AllActivityTable {...props} />
    </>
  );
};

export default UserActivityView;
