import axios from "axios";
import Bugsnag from '@bugsnag/js';

// CLASSES
import Settings from "@classes/Settings";

export default function apiRequest(path, data, props = {}, returnRaw = false)
{
	if (!data)
	{
		data = {};
	}

	let apiUrl = import.meta.env.VITE_API_URL
	const axiosTimeout = import.meta.env.VITE_AXIOS_TIMEOUT;
	if (apiUrl === "/")
	{ // If we set the api url we will use the same host on port 80 as the api server
		apiUrl = window.location.protocol + "//" + window.location.hostname;
	}

	if (import.meta.env.VITE_NODE_ENV === "development")
	{
		const apiServerPort = import.meta.env.VITE_API_SERVER_PORT;
		apiUrl = apiServerPort ? apiUrl + ":" + apiServerPort : apiUrl;
	}

	const url = path[0] === "/" ? apiUrl + path : apiUrl + "/" + path;
	const headers = {};
	const authToken = Settings.get("AUTH_TOKEN");

	if (authToken !== null)
	{
		data.authToken = authToken;
	}

	const config = {
		method: "post",
		data,
		url,
		axiosTimeout,
		...props,
		headers: {...headers, ...props.headers}
	};

  const handleErrorResponse = (data, reject) => {  
    if (data.error) {
      const errorMessage = data.error.message;
      const error = new Error(errorMessage || "UNKNOWN ERROR");
      switch (errorMessage) {
        case "USER_ALREADY_EXISTS":
        case "RESELLER_ALREADY_EXISTS":
          // Expected error handled in the component, no need to bugsnag
          reject(error);
          break;
        case "USER_NOT_ACTIVE":
        case "AUTH_TOKEN_REJECTED":
        case "AUTH_TOKEN_UNDEFINED":
        case "AUTH_TOKEN_INVALID":
          Settings.clear();
          window.location = "/";
          reject(error);
          break;
        default:
          Bugsnag.notify(error);
          reject(error);
          break;
      }
    }
  }

	return new Promise((resolve, reject) =>
	{
		axios(config).then(response =>
		{
			if (returnRaw)
			{
        // Check response for errors
        handleErrorResponse(response.data, reject);

				resolve(response.data);
				return;
			}

			if (typeof response.data === "string")
			{
				Bugsnag.notify(new Error(response.data));
				reject(new Error(response.data));
				return;
			}

			if (response.data.success === true)
			{
				resolve(response.data.result);
			}
			else
			{
        handleErrorResponse(response.data, reject);
			}
		}).catch(error =>
		{
			Bugsnag.notify(error);
			reject(error);
		});
	});
}