import React from "react";
import PropTypes from "prop-types";

import { isEmptyObject } from "@helpers/utils";

import {
  Modal as MuiModal,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Alert
} from "@mui/material";

// import Button from "@components/Button/Button";
import ModalContentBox from "@mui-theme/styled/ModalContentBox";
import CloseModalBox from "@mui-theme/styled/CloseModalBox";
import ThemedButton from "@mui-theme/styled/ThemedButton";

import useAddCompanyModal from "./useAddCompanyModal";

const AddUserModal = props => {
  const {
    state,
    hasId,
    canSubmitForm,
    toggle,
    isValidInput,
    handleInputChange,
    handleSubmit
  } = useAddCompanyModal(props);

  const {
    action,
    orgNumber,
    companyName,
    addressStreet,
    addressZip,
    addressCity,
    email,
    isPartner,
    fetching,
    inputErrors,
    serverMessage
  } = state;

  return (
    <MuiModal
      open={true}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          toggle();
        }
      }}
      sx={{
        overflow: "scroll"
      }}
    >
      <ModalContentBox
        sx={{
          maxHeight: "96%"
        }}
      >
        <Box
          sx={{
            width: "100%",
            justifyContent: "flex-start"
          }}
        >
          <Box
            sx={{
              width: "100%",
              px: 2,
              alignItems: "flex-start"
            }}
          >
            <Typography variant="h4" color="common.black">
              {action === "add" ? "Ny användare" : "Redigera användare"}
            </Typography>
          </Box>
          <Box
            component="form"
            noValidate
            sx={{
              width: "100%",
              alignItems: "flex-start",
              display: "flex",
              flexFlow: "column",
              px: 2
            }}
          >
            {/* Basic Information */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                // ...(action === "update" && { mt: 1 })
                mt: action === "update" ? 1 : 0.5
              }}
            >
              <TextField
                fullWidth
                autoFocus={action === "add" ? true : false}
                color={
                  isValidInput("orgNumber", orgNumber) ? "success" : "primary"
                }
                size="small"
                id="orgNumber"
                name="orgNumber"
                label="Organisationsnummer"
                value={orgNumber}
                disabled={hasId ? true : false}
                error={inputErrors?.orgNumber ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.orgNumber}
                sx={{
                  width: "25%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 2.5,
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)"
                  },
                  "& input": {
                    width: "95%",
                    cursor: hasId ? "not-allowed" : "auto"
                  },
                  "& fieldset": {
                    borderColor: isValidInput("orgNumber", orgNumber)
                      ? "#2e7d32"
                      : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
              <FormControl
                sx={{
                  height: 40,
                  alignItems: "flex-start",
                  width: "12.5%",
                  mb: 1,
                  ml: 2.5,
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)",
                    "&.Mui-focused": {
                      color: "#2e7d32 !important"
                    }
                  }
                }}
              >
                <InputLabel id="resellerId" htmlFor="resellerId">
                  Är partner
                </InputLabel>
                <Select
                  size="small"
                  id="isPartner"
                  name="isPartner"
                  label="Är partner"
                  value={isPartner}
                  onChange={e => {
                    handleInputChange(e);
                  }}
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    alignItems: "flex-start",
                    "& fieldset": {
                      borderColor: "#2e7d32"
                    }
                  }}
                >
                  <MenuItem
                    key={0}
                    value={false}
                    sx={{
                      width: "100%",
                      fontSize: "1rem",
                      alignItems: "flex-start"
                    }}
                  >
                    <Typography>Nej</Typography>
                  </MenuItem>
                  <MenuItem
                    key={1}
                    value={true}
                    sx={{
                      width: "100%",
                      fontSize: "1rem",
                      alignItems: "flex-start"
                    }}
                  >
                    <Typography>Ja</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mb: 1.5
              }}
            >
              <TextField
                fullWidth
                color={
                  isValidInput("companyName", companyName)
                    ? "success"
                    : "primary"
                }
                size="small"
                id="companyName"
                name="companyName"
                autoComplete="companyName"
                label="Företagsnamn"
                value={companyName}
                error={inputErrors?.companyName ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.companyName}
                sx={{
                  width: "49%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 1,
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)"
                  },
                  "& fieldset": {
                    borderColor: isValidInput("companyName", companyName)
                      ? "#2e7d32"
                      : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
            </Box>
            {/* Contact */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <TextField
                size="small"
                fullWidth
                color={
                  isValidInput("addressStreet", addressStreet)
                    ? "success"
                    : "primary"
                }
                id="addressStreet"
                name="addressStreet"
                autoComplete="addressStreet"
                label="Gatuaddress"
                value={addressStreet}
                error={inputErrors?.addressStreet ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.addressStreet}
                sx={{
                  width: "32%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 3,
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)"
                  },
                  "& fieldset": {
                    borderColor: isValidInput("addressStreet", addressStreet)
                      ? "#2e7d32"
                      : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
              <TextField
                size="small"
                fullWidth
                color={
                  isValidInput("addressZip", addressZip) ? "success" : "primary"
                }
                id="addressZip"
                name="addressZip"
                autoComplete="addressZip"
                label="Postnummer"
                value={addressZip}
                error={inputErrors?.addressZip ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.addressZip}
                sx={{
                  width: "32%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 3,
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)"
                  },
                  "& fieldset": {
                    borderColor: isValidInput("addressZip", addressZip)
                      ? "#2e7d32"
                      : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
              <TextField
                size="small"
                fullWidth
                color={
                  isValidInput("addressCity", addressCity) ? "success" : "primary"
                }
                id="addressCity"
                name="addressCity"
                autoComplete="addressCity"
                label="Postort"
                value={addressCity}
                error={inputErrors?.addressCity ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.addressCity}
                sx={{
                  width: "32%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 3,
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)"
                  },
                  "& fieldset": {
                    borderColor: isValidInput("addressCity", addressCity)
                      ? "#2e7d32"
                      : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                ...(action === "update" && { mt: 1 })
              }}
            >
              <TextField
                size="small"
                fullWidth
                color={isValidInput("email", email) ? "success" : "primary"}
                id="email"
                name="email"
                autoComplete="email"
                label="E-postaddress"
                value={email}
                error={inputErrors?.email ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.email}
                sx={{
                  width: "32%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 3,
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)"
                  },
                  "& fieldset": {
                    borderColor: isValidInput("email", email)
                      ? "#2e7d32"
                      : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                height: 50,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Alert
                severity={
                  serverMessage?.type === "success" ? "success" : "error"
                }
                sx={{
                  display: serverMessage?.text ? "flex" : "none",
                  width: "100%"
                }}
              >
                {serverMessage?.text}
              </Alert>
            </Box>
          </Box>
        </Box>
        <CloseModalBox
          sx={{
            px: 2
          }}
        >
          <ThemedButton
            variant="outlined"
            onClick={toggle}
            sx={{
              minWidth: "unset",
              width: 100
            }}
          >
            Avbryt
          </ThemedButton>
          <ThemedButton
            variant="contained"
            loading={fetching}
            disabled={
              !canSubmitForm || (inputErrors && !isEmptyObject(inputErrors))
            }
            onClick={handleSubmit}
            sx={{
              minWidth: "unset",
              width: 150
            }}
          >
            Spara användare
          </ThemedButton>
        </CloseModalBox>
      </ModalContentBox>
    </MuiModal>
  );
};

AddUserModal.propTypes = {
  open: PropTypes.bool
};

export default AddUserModal;
