import React, { useState, useEffect } from "react";
import i18n from "@helpers/i18n";

import HomeIcon from "@mui/icons-material/House";
import DashboardSessionsIcon from "@mui/icons-material/BorderColorOutlined";
import DashboardPoasIcon from "@mui/icons-material/InfoOutlined";
import FutureIcon from "@mui/icons-material/AccountBalanceOutlined";
import DashboardKycsIcon from "@mui/icons-material/FactCheckOutlined";
import DashboardOtherIcon from "@mui/icons-material/DynamicFeed";
import DashboardReportsIcon from "@mui/icons-material/DescriptionOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { getObjectKeyByValue } from "@helpers/utils";
import useResponsive from "@hooks/useResponsive";
import { MenuDrawerConsumer } from "@contexts/MenuDrawerProvider";

const useMenuDrawer = props => {
  const isLaptopOrDesktop = useResponsive("up", "md");
  const menuDrawerCtxt = MenuDrawerConsumer();
  const open = isLaptopOrDesktop ? true : menuDrawerCtxt.state.open;
  const [height, setHeight] = useState(1000);

  const { partner, user, modules, onToggle, setCaseFilter } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedChild, setSelectedChild] = useState(null);
  const [nestedItems, setNestedItems] = useState({});

  const adminOptions = partner.modules.find(module => module.key === "admin")
    ?.widgets[0];

  const customProps = {
    variant: isLaptopOrDesktop ? "permanent" : "temporary"
  };

  const toggleDrawer = () => e => {
    if (
      isLaptopOrDesktop ||
      (e && e.type === "keydown" && (e.key === "Tab" || e.key === "Shift"))
    ) {
      return;
    }
    return menuDrawerCtxt.toggleDrawer();
  };

  // Handle parent menu item click
  const handleItemClick = item => e => {
    e.preventDefault;
    if (item.name !== getObjectKeyByValue(nestedItems, true)) {
      // if it's not a currently opened nested item
      setNestedItems({});
      item.handleNavigation();
      if (item.children) {
        setNestedItems(prevState => ({
          ...prevState,
          [item.name]: !prevState[item.name]
        }));
        setSelectedChild(
          item.children.length > 0 ? item.children[0].name : null
        );
      }
      menuDrawerCtxt.setState(prevState => ({
        ...prevState,
        selectedMenu: item.name,
        selectedChild:
          item.children && item.children.length > 0
            ? item.children[0].name
            : null
      }));
    }
    // reset case filter (force using default tab)
    setCaseFilter(null);
    // scroll to top
    window.scrollTo(0, 0);
    if (isLaptopOrDesktop) return;
    return menuDrawerCtxt.toggleDrawer();
  };

  // Handle nested children click
  const handleChildClick = child => e => {
    e.preventDefault;
    child.handleNavigation();
    menuDrawerCtxt.setState(prevState => ({
      ...prevState,
      selectedChild: child.name
    }));
    // scroll to top
    window.scrollTo(0, 0);
    if (isLaptopOrDesktop) return;
    return menuDrawerCtxt.toggleDrawer();
  };

  const menu = [];
  if (modules && modules.length > 0) {
    modules.forEach((item, index) => {
      let permittedByUserAccess = false;
      if (
        item.userPermission !== undefined &&
        user.hasPermission(item.userPermission)
      ) {
        permittedByUserAccess = true;
      }

      if (
        !item.allowedRoles ||
        item.allowedRoles.includes(user.role) ||
        permittedByUserAccess
      ) {
        const newItem = extendMenuItem(
          adminOptions,
          item,
          index,
          setSelectedIndex,
          setSelectedChild,
          onToggle
        );
        menu.push(newItem);
      }
    });
  }

  return {
    open,
    height,
    customProps,
    nestedItems,
    selectedIndex,
    selectedChild,
    handleItemClick,
    handleChildClick,
    menu,
    toggleDrawer
  };
};

const iconsMap = {
  start: <HomeIcon />,
  sessions: <DashboardSessionsIcon />,
  poa: <DashboardPoasIcon />,
  futur: <FutureIcon />,
  kyc: <DashboardKycsIcon />,
  other: <DashboardOtherIcon />,
  reports: <DashboardReportsIcon />,
  admin: <AdminPanelSettingsIcon />
};

const extendMenuItem = (
  adminOptions,
  item,
  index,
  setSelectedIndex,
  setSelectedChild,
  onToggle
) => {
  const adminDrawer = [
    {
      name: "users",
      label: "Användare",
      // icon: <StarBorder />,
      handleNavigation: () => {
        return setSelectedChild("users");
      }
    },
    ...(adminOptions.showCompanies
      ? [
          {
            name: "user_companies",
            label: "Företag",
            // icon: <StarBorder />,
            handleNavigation: () => {
              return setSelectedChild("user_companies");
            }
          }
        ]
      : []),
    {
      name: "user_activity",
      label: "Användaraktivitet",
      // icon: <StarBorder />,
      handleNavigation: () => {
        return setSelectedChild("user_activity");
      }
    }
  ];

  return {
    name: item.key,
    label: i18n("dashboard_selector", item.key),
    icon: iconsMap[item.key],
    ...(item.key === "admin" && { children: adminDrawer }),
    handleNavigation: () => {
      setSelectedIndex(index);
      return onToggle(item.key);
    }
  };
};

export default useMenuDrawer;
