import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// COMPONENTS
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const UsersTableMenu = props => {
  const {
    isSelf,
    user,
    setSelectedUser,
    toggleAddUserModal,
    toggleUserStatusModal
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelection = async option => {
    setSelectedUser(user);

    if (option === "edit") {
      toggleAddUserModal("edit");
    }

    if (option === "deactivate") {
      toggleUserStatusModal();
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Välj åtgärd">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ height: "auto", p: 0, pb: 0.3, m: 0 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MenuIcon
              sx={{
                color: "#AAA",
                width: 16,
                height: 16,
                mr: 1
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            pb: 0,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              topx: 1,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => handleOptionSelection("edit")}
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-start",
            px: 1,
            height: 35,
            minHeight: "unset"
          }}
        >
          Redigera
        </MenuItem>
        {!isSelf && (
          <MenuItem
            onClick={() => handleOptionSelection("deactivate")}
            sx={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-start",
              px: 1,
              height: 35,
              minHeight: "unset"
            }}
          >
            {user.is_active === "1" ? "Inaktivera" : "Aktivera"}
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
};

export default UsersTableMenu;

UsersTableMenu.propTypes = {
  handlers: PropTypes.object
};
