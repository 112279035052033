import React, { Fragment } from "react";

import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

import useMenuDrawer from "./useMenuDrawer";

const MenuDrawer = props => {
  const {
    open,
    // height,
    nestedItems,
    customProps,
    selectedIndex,
    selectedChild,
    handleItemClick,
    handleChildClick,
    menu,
    toggleDrawer
  } = useMenuDrawer(props);

  const list = () => (
    <Box role="presentation" sx={{ width: "100%", p: 0 }}>
      <List sx={{ width: "100%", mt: 1, p: 0 }}>
        {menu.map((item, index) => (
          <Fragment key={item.label}>
            <ListItem disablePadding>
              <ListItemButton
                disableRipple
                sx={[
                  {
                    flexDirection: "row",
                    width: "95%",
                    alignItems: "flex-start",
                    color: "common.white",
                    backgroundColor:
                      selectedIndex === index ? "primary.dark" : "unset",
                    borderRadius: 2,
                    px: 2,
                    m: 0.5
                  },
                  {
                    "&:hover": {
                      backgroundColor: "primary.darker"
                    }
                  }
                ]}
                onClick={handleItemClick(item, index)}
              >
                <ListItemIcon
                  variant="active"
                  sx={{
                    alignItems: "flex-start",
                    "& .MuiSvgIcon-root": { mt: 0.2, fontSize: "1.4rem" }
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    variant: "menuDrawer",
                    ...(selectedIndex === index && {
                      sx: {
                        alignItems: "flex-start",
                        color: "inherit",
                        fontWeight: 700
                      }
                    })
                  }}
                  sx={{ alignItems: "flex-start" }}
                />
                {item?.children &&
                  item?.children.length > 0 &&
                  (nestedItems[item.name] ? (
                    <ArrowDropUp />
                  ) : (
                    <ArrowDropDown />
                  ))}
              </ListItemButton>
            </ListItem>
            {item?.children && item?.children.length > 0 && (
              <Collapse
                in={nestedItems[item.name]}
                timeout="auto"
                sx={{ width: "100%", p: 0 }}
                unmountOnExit
              >
                <List component="div" sx={{ width: "100%", p: 0 }}>
                  {item?.children.map((child, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        disableRipple
                        sx={[
                          {
                            flexDirection: "row",
                            width: "92.5%",
                            alignItems: "flex-start",
                            ml: 0.75,
                            my: 0.25,
                            py: 0.25,
                            color: "common.white",
                            textDecoration: selectedChild === child.name ? "underline" : "none",
                          },
                          {
                            "&:hover": {
                              textDecoration: selectedChild === child.name ? "underline" : "none",
                              backgroundColor: "primary.darker"
                            }
                          }
                        ]}
                        onClick={handleChildClick(child, index)}
                      >
                        <ListItemText
                          primary={child.label}
                          primaryTypographyProps={{
                            variant: "menuDrawer",
                            sx: {
                              ...(selectedChild === child.name && {
                                color: "inherit"
                              }),
                              fontSize: "13px !important",
                              ml: 3,
                              whiteSpace: "nowrap",
                              maxWidth: 150,
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }
                          }}
                          sx={{ alignItems: "start" }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <SwipeableDrawer
      PaperProps={{
        sx: {
          position: { md: "relative" },
          width: { xs: "65%", md: "100%" },
          top: { xs: "75px", md: "60px" },
          overflow: "auto",
          borderRightColor: "gray.400",
          backgroundColor: "primary.main",
          zIndex: 999
        }
      }}
      anchor="left"
      open={open}
      onClose={toggleDrawer()}
      onOpen={toggleDrawer()}
      sx={{
        height: "100%",
        position: "fixed",
        left: 0,
        width: { md: "250px", lg: "270px" },
        zIndex: 999
      }}
      {...customProps}
    >
      {list()}
    </SwipeableDrawer>
  );
};

export default MenuDrawer;
