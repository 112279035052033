import { useState, useEffect } from "react";
// HELPERS
import api from "@helpers/api";
import { formatSSN } from "@helpers/utils";

const useCompaniesTable = () => {
  const [loaded, setLoaded] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("first_name");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [copyList, setCopyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);
  const [companyStatusModalOpen, setCompanyStatusModalOpen] = useState(false);
  const [newCompanyAdded, setNewCompanyAdded] = useState(null);
  const [activeTab, setActiveTab] = useState("1");

  let columns = [];
  columns.push(
    {
      header: "",
      id: "menuButton",
      accessor: "menuButton",
      width: "2.5%",
      disablePadding: true,
      disableSort: true
    },
    {
      header: "Organisationsnr",
      accessor: "org_number",
      width: "12.5%"
    },
    {
      header: "Namn",
      accessor: "company_name",
      width: "25%"
    },
    {
      header: "Gatuadreess",
      accessor: "address_street",
      width: "30%"
    },
    {
      header: "Postnummer",
      accessor: "adress_zip",
      width: "10%"
    },
    {
      header: "Postort",
      accessor: "adress_city",
      width: "10%"
    },
    {
      header: "Typ",
      accessor: "type",
      width: "10%"
    }
  );

  const roles = {
    administrator: 1,
    backoffice: 2,
    advisor: 3,
    sales: 4,
    assistant: 5
  };

  const tabs = [
    {
      key: "1",
      label: "Aktiva"
    },
    {
      key: "0",
      label: "Inaktiva"
    }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setPage(0);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleTabChange = newTab => {
    setCopyList(companies.filter(company => company.is_active === newTab));
    setActiveTab(newTab);
  };

  const updateCompanies = company => {
    // find existing index
    let index = companies.findIndex(
      u =>
        (u.org_number &&
          company.orgNumber &&
          u.org_number === company.orgNumber) ||
        (u.orgNumber &&
          company.orgNumber &&
          u.orgNumber === company.orgNumber) ||
        (u.org_number &&
          company.org_number &&
          u.org_number === company.org_number)
    );

    const isNewCompany = index === -1;
    // if not found, add to end (new company)
    if (isNewCompany) {
      index = companies.length;
      setNewCompanyAdded(company);

      setTimeout(() => {
        setNewCompanyAdded(null);
      }, 3000);
    }
    // update companies with new company record
    companies[index] = company;
    // sort alphabetically
    companies.sort((a, b) =>
      (a.company_name || a.companyName).localeCompare(
        b.company_name || b.companyName,
        "sv",
        { sensitivity: "base" }
      )
    );

    return setCopyList(
      companies.filter(company => company.is_active == activeTab)
    );
  };

  const requestSearch = searched => {
    if (companies) {
      if (page > 0) setPage(0);
      const searchString = searched.toLowerCase();
      setCopyList(
        companies.filter(company => {
          const orgNumWithOutDash = formatSSN(company.org_number, {
            withDash: false
          });

          return (
            company.is_active === activeTab &&
            (company.company_name.toLowerCase()
              .includes(searchString) ||
              company.org_number?.toLowerCase().includes(searchString) ||
              orgNumWithOutDash?.toLowerCase().includes(searchString))
          );
        })
      );
    }
  };

  const toggleAddCompanyModal = action => {
    if (action === "add") setSelectedCompany(null);

    if (addCompanyModalOpen) {
      // disable/enable background content scrolling while modal is open
      document.documentElement.style.overflow = "auto";
    } else {
      document.documentElement.style.overflow = "hidden";
    }

    setAddCompanyModalOpen(!addCompanyModalOpen);
  };

  const toggleCompanyStatusModal = () => {
    if (companyStatusModalOpen) {
      // disable/enable background content scrolling while modal is open
      document.documentElement.style.overflow = "auto";
    } else {
      document.documentElement.style.overflow = "hidden";
    }

    setCompanyStatusModalOpen(!companyStatusModalOpen);
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const resellers = await api("/partner/resellers");
      setCompanies(resellers);
      setCopyList(
        resellers.filter(reseller => reseller.is_active == activeTab)
      );
      setLoaded(true);
    };

    if (!loaded) {
      fetchCompanies();
    }
  }, [loaded]);

  return {
    loaded,
    columns,
    roles,
    tabs,
    activeTab,
    copyList,
    page,
    order,
    orderBy,
    rowsPerPage,
    selectedCompany,
    setPage,
    setSelectedCompany,
    setRowsPerPage,
    handleTabChange,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    toggleAddCompanyModal,
    toggleCompanyStatusModal,
    updateCompanies,
    addCompanyModalOpen,
    companyStatusModalOpen,
    newCompanyAdded
  };
};

export default useCompaniesTable;
