import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo
} from "react";
import PropTypes from "prop-types";

const MenuDrawerCtxt = createContext(null);

const MenuDrawerProvider = ({ children }) => {
  const [state, setState] = useState({ open: false });

  // toggle drawer, memoized
  const toggleDrawer = useCallback(() => {
    setState(prevState => ({
      open: !prevState.open
    }));
  }, []);

  const contextValue = useMemo(
    () => ({
      state,
      setState,
      toggleDrawer
    }),
    [state, setState, toggleDrawer]
  );

  return (
    <MenuDrawerCtxt.Provider value={contextValue}>
      {children}
    </MenuDrawerCtxt.Provider>
  );
};

export const MenuDrawerConsumer = () => {
  return useContext(MenuDrawerCtxt);
};

export default MenuDrawerProvider;

MenuDrawerProvider.propTypes = {
  children: PropTypes.node
};
