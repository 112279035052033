import { useState, useRef, useEffect } from "react";
import api from "@helpers/api";
// import useResponsive from "@hooks/useResponsive";

import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";

const useNotesDrawer = props => {
  // const isLaptopOrDesktop = useResponsive("up", "md");
  const { open, user, customer, viewId, onToggle } = props;
  const { caseId } = customer;

  const [loaded, setLoaded] = useState(false);
  const [hasNotes, setHasNotes] = useState(null);
  const [noteSaved, setNoteSaved] = useState(false);
  const drawerRef = useRef(null);
  const editorRef = useRef(null);
  const searchTimeout = useRef(0);

  const customProps = {};

  const showNoteSaved = () => {
    setNoteSaved(true);
    setTimeout(() => {
      setNoteSaved(false);
    }, 5000);
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Placeholder.configure({
        placeholder: "Spara dina anteckningar gällande ärendet här."
      })
    ],
    // content: "<p>Spara dina anteckningar gällande ärendet här.</p>",
    onUpdate: ({ editor }) => {
      if (searchTimeout.current > 0) clearTimeout(searchTimeout.current);
      searchTimeout.current = setTimeout(() => {
        if (!hasNotes) {
          caseNote("add");
        } else {
          caseNote("edit");
        }
      }, 2000);
    }
  });

  const caseNote = async action => {
    // Add case note
    await api(`case/notes/${action}`, {
      caseId,
      viewId,
      note: editor.getHTML()
    });

    showNoteSaved();
    return setHasNotes(true);
  };

  // get notes
  useEffect(() => {
    const fetchCaseNotes = async () => {
      const response = await api("case/notes", { caseId, viewId }, {}, true);
      const { success, result } = response;
      if (success && result) {
        editor.commands.setContent(result.Note);
        setHasNotes(true);
      }
      setLoaded(true);
    };
    if (!loaded && caseId) fetchCaseNotes();
  }, [loaded, caseId, viewId]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (event.target.id === "toggle-notes-drawer-btn") return; // just let the footer button do its thing

      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        onToggle();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [open]);

  return {
    open,
    drawerRef,
    editorRef,
    onToggle,
    customProps,
    editor,
    noteSaved,
    showNoteSaved
  };
};

export default useNotesDrawer;
