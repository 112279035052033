import React from "react";
import PropTypes from "prop-types";
import i18n from "@helpers/i18n";

import { isEmptyObject } from "@helpers/utils";

import {
  Modal as MuiModal,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Alert
} from "@mui/material";

// import Button from "@components/Button/Button";
import ModalContentBox from "@mui-theme/styled/ModalContentBox";
import CloseModalBox from "@mui-theme/styled/CloseModalBox";
import ThemedButton from "@mui-theme/styled/ThemedButton";

import useAddUserModal from "./useAddUserModal";

const AddUserModal = props => {
  const {
    isSelf,
    showCustomerNumber,
    showFuturKod,
    state,
    hasId,
    roles,
    resellers,
    canSubmitForm,
    toggle,
    isValidInput,
    handleInputChange,
    handleSubmit
  } = useAddUserModal(props);

  const {
    action,
    personalNumber,
    roleId,
    firstName,
    lastName,
    email,
    phoneHome,
    phoneMobile,
    customerNumber,
    futurCode,
    resellerId,
    fetching,
    inputErrors,
    serverMessage
  } = state;

  return (
    <MuiModal
      open={true}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          toggle();
        }
      }}
      sx={{
        overflow: "scroll"
      }}
    >
      <ModalContentBox
        sx={{
          maxHeight: "96%"
        }}
      >
        <Box
          sx={{
            width: "100%",
            justifyContent: "flex-start"
          }}
        >
          <Box
            sx={{
              width: "100%",
              px: 2,
              alignItems: "flex-start"
            }}
          >
            <Typography variant="h4" color="common.black">
              {action === "add" ? "Ny användare" : "Redigera användare"}
            </Typography>
          </Box>
          <Box
            component="form"
            noValidate
            sx={{
              width: "100%",
              alignItems: "flex-start",
              display: "flex",
              flexFlow: "column",
              px: 2
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                ...(action === "update" && { mt: 1 })
              }}
            >
              <FormControl
                sx={{
                  height: 40,
                  alignItems: "flex-start",
                  width: { xs: "100%", sm: 230 },
                  mb: 1
                }}
              >
                <InputLabel id="roleId" htmlFor="roleId">
                  Roll
                </InputLabel>
                <Select
                  size="small"
                  id="roleId"
                  name="roleId"
                  label="Roll"
                  value={roleId}
                  onChange={e => {
                    handleInputChange(e);
                  }}
                  sx={{
                    width: 140,
                    textAlign: "left",
                    alignItems: "flex-start",
                    "& fieldset": {
                      borderColor: "#2e7d32"
                    },
                    "& .Mui-disabled": {
                      cursor: "not-allowed"
                    }
                  }}
                  disabled={isSelf}
                >
                  {Object.keys(roles).map(value => {
                    const roleId = roles[value];
                    return (
                      <MenuItem
                        key={roleId}
                        value={roleId}
                        sx={{
                          width: "100%",
                          fontSize: "1rem",
                          alignItems: "flex-start"
                        }}
                      >
                        {i18n("user_roles", value)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>

            {/* Basic Information */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent:
                  showCustomerNumber && showFuturKod
                    ? "space-between"
                    : "flex-start",
                alignItems: "flex-start",
                // ...(action === "update" && { mt: 1 })
                mt: action === "update" ? 1 : 0.5
              }}
            >
              <TextField
                fullWidth
                autoFocus={action === "add" ? true : false}
                color={
                  isValidInput("personalNumber", personalNumber)
                    ? "success"
                    : "primary"
                }
                size="small"
                id="personalNumber"
                name="personalNumber"
                label="Personnummer"
                value={personalNumber}
                disabled={hasId ? true : false}
                error={inputErrors?.personalNumber ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.personalNumber}
                sx={{
                  width: "25%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 2.5,
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)"
                  },
                  "& input": {
                    cursor: hasId ? "not-allowed" : "auto"
                  },
                  "& fieldset": {
                    borderColor: isValidInput("personalNumber", personalNumber)
                      ? "#2e7d32"
                      : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
              {showCustomerNumber && (
                <TextField
                  fullWidth
                  color={
                    isValidInput("customerNumber", customerNumber)
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  id="customerNumber"
                  name="customerNumber"
                  label="Rådgivarkod"
                  value={customerNumber}
                  error={inputErrors?.customerNumber ? true : false}
                  onChange={e => {
                    handleInputChange(e);
                  }}
                  helperText={inputErrors?.customerNumber}
                  sx={{
                    width: "25%",
                    height: 40,
                    alignItems: "flex-start",
                    mb: 2.5,
                    ...(!showFuturKod && { ml: 2.5 }),
                    "& label": {
                      color: "rgba(0, 0, 0, 0.35)"
                    },
                    "& fieldset": {
                      borderColor: isValidInput(
                        "customerNumber",
                        customerNumber
                      )
                        ? "#2e7d32"
                        : "rgba(0, 0, 0, 0.23)"
                    }
                  }}
                />
              )}
              {showFuturKod && (
                <TextField
                  fullWidth
                  color={
                    isValidInput("futurCode", futurCode) ? "success" : "primary"
                  }
                  size="small"
                  id="futurCode"
                  name="futurCode"
                  label="Futurkod"
                  value={futurCode}
                  error={inputErrors?.futurCode ? true : false}
                  onChange={e => {
                    handleInputChange(e);
                  }}
                  helperText={inputErrors?.futurCode}
                  sx={{
                    width: "15%",
                    height: 40,
                    alignItems: "flex-start",
                    mb: 2.5,
                    ...(!showCustomerNumber && { ml: 2.5 }),
                    "& label": {
                      color: "rgba(0, 0, 0, 0.35)"
                    },
                    "& fieldset": {
                      borderColor: isValidInput("futurCode", futurCode)
                        ? "#2e7d32"
                        : "rgba(0, 0, 0, 0.23)"
                    }
                  }}
                />
              )}
              <FormControl
                sx={{
                  height: 40,
                  alignItems: "flex-start",
                  width: "30%",
                  mb: 1,
                  ...((!showCustomerNumber || !showFuturKod) && { ml: 2.5 }),
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)",
                    "&.Mui-focused": {
                      color: "#2e7d32 !important"
                    }
                  }
                }}
              >
                <InputLabel id="resellerId" htmlFor="resellerId">
                  Ombud
                </InputLabel>
                <Select
                  size="small"
                  id="resellerId"
                  name="resellerId"
                  label="Ombud"
                  value={resellerId}
                  onChange={e => {
                    handleInputChange(e);
                  }}
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    alignItems: "flex-start",
                    "& fieldset": {
                      borderColor: "#2e7d32"
                    }
                  }}
                >
                  {resellers.map(reseller => {
                    return (
                      <MenuItem
                        key={reseller.reseller_id}
                        value={reseller.reseller_id}
                        sx={{
                          width: "100%",
                          fontSize: "1rem",
                          alignItems: "flex-start"
                        }}
                      >
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          {reseller.company_name}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mb: 1.5,
                mt: 1
              }}
            >
              <TextField
                fullWidth
                color={
                  isValidInput("firstName", firstName) ? "success" : "primary"
                }
                size="small"
                id="firstName"
                name="firstName"
                label="Förnamn"
                value={firstName}
                error={inputErrors?.firstName ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.firstName}
                sx={{
                  width: "49%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 1,
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)"
                  },
                  "& fieldset": {
                    borderColor: isValidInput("firstName", firstName)
                      ? "#2e7d32"
                      : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
              <TextField
                fullWidth
                color={
                  isValidInput("lastName", lastName) ? "success" : "primary"
                }
                size="small"
                id="lastName"
                name="lastName"
                autoComplete="lastName"
                label="Efternamn"
                value={lastName}
                error={inputErrors?.lastName ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.lastName}
                sx={{
                  width: "49%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 1,
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)"
                  },
                  "& fieldset": {
                    borderColor: isValidInput("lastName", lastName)
                      ? "#2e7d32"
                      : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
            </Box>
            {/* Contact */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 1
              }}
            >
              <TextField
                size="small"
                fullWidth
                color={isValidInput("email", email) ? "success" : "primary"}
                id="email"
                name="email"
                label="E-postadress"
                value={email}
                error={inputErrors?.email ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.email}
                sx={{
                  width: "32%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 3,
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)"
                  },
                  "& fieldset": {
                    borderColor: isValidInput("email", email)
                      ? "#2e7d32"
                      : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
              <TextField
                size="small"
                fullWidth
                color={
                  isValidInput("phoneHome", phoneHome) ? "success" : "primary"
                }
                id="phoneHome"
                name="phoneHome"
                label="Telefonnummer"
                value={phoneHome}
                error={inputErrors?.phoneHome ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.phoneHome}
                sx={{
                  width: "32%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 3,
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)"
                  },
                  "& fieldset": {
                    borderColor: isValidInput("phoneHome", phoneHome)
                      ? "#2e7d32"
                      : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
              <TextField
                size="small"
                fullWidth
                color={
                  isValidInput("phoneHome", phoneMobile) ? "success" : "primary"
                }
                id="phoneMobile"
                name="phoneMobile"
                label="Mobilnummer"
                value={phoneMobile}
                error={inputErrors?.phoneMobile ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.phoneMobile}
                sx={{
                  width: "32%",
                  height: 40,
                  alignItems: "flex-start",
                  mb: 3,
                  "& label": {
                    color: "rgba(0, 0, 0, 0.35)"
                  },
                  "& fieldset": {
                    borderColor: isValidInput("phoneMobile", phoneMobile)
                      ? "#2e7d32"
                      : "rgba(0, 0, 0, 0.23)"
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                height: 50,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Alert
                severity={
                  serverMessage?.type === "success" ? "success" : "error"
                }
                sx={{
                  display: serverMessage?.text ? "flex" : "none",
                  width: "100%"
                }}
              >
                {serverMessage?.text}
              </Alert>
            </Box>
          </Box>
        </Box>
        <CloseModalBox
          sx={{
            px: 2
          }}
        >
          <ThemedButton
            variant="outlined"
            onClick={toggle}
            sx={{
              minWidth: "unset",
              width: 100
            }}
          >
            Avbryt
          </ThemedButton>
          <ThemedButton
            variant="contained"
            loading={fetching}
            disabled={
              !canSubmitForm || (inputErrors && !isEmptyObject(inputErrors))
            }
            onClick={handleSubmit}
            sx={{
              minWidth: "unset",
              width: 150
            }}
          >
            Spara användare
          </ThemedButton>
        </CloseModalBox>
      </ModalContentBox>
    </MuiModal>
  );
};

AddUserModal.propTypes = {
  open: PropTypes.bool
};

export default AddUserModal;
