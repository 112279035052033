import { useState, useEffect, useRef } from "react";
import i18n from "@helpers/i18n";
import api from "@helpers/api";
import { getCookie } from "@helpers/cookies";
import { formatSSN } from "@helpers/utils";

// HOOKS
import useResponsive from "@hooks/useResponsive";

const useAllActivityTable = props => {
  const {
    data,
    totalCases,
    user,
    isLoadingCases,
    partner,
    advisor,
    caseFilterContext,
    setCustomerState,
    onWorkflowSwitched,
    nextStepOptions
  } = props;

  const isMobileOrTablet = useResponsive("down", "md");
  const [mounted, setMounted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [copyList, setCopyList] = useState(data);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [addCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [numCases, setNumCases] = useState(totalCases);
  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState("");
  const searchTimeout = useRef(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created");
  const [filters, setFilters] = useState({
    user: null,
    client: null,
    search: ""
  });

  // check if we have any options saved..
  const cobOptions = JSON.parse(getCookie("cobOptions")) || null;
  // .. and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(
    cobOptions?.rowsPerPage["futur"] || 10
  );

  let columns = [];
  columns.push(
    {
      Header: `${i18n("tables", "time")}`,
      className: "Created",
      accessor: "Created",
      width: "10%"
    },
    {
      Header: `${i18n("tables", "user")}`,
      className: "UserFirstName",
      accessor: "UserFirstName",
      width: "30%"
    },
    {
      Header: `${i18n("tables", "activity")}`,
      className: "Action",
      accessor: "Action",
      width: "60%",
      disableSort: true
    }
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setPage(0);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const toggleAddCustomerModal = action => {
    if (!customerModalOpen && (action === "add" || addCustomerModalOpen))
      setSelectedCustomer(null);

    if (addCustomerModalOpen) {
      // disable/enable background content scrolling while modal is open
      document.documentElement.style.overflow = "auto";
    } else {
      document.documentElement.style.overflow = "hidden";
    }

    setAddCustomerModalOpen(!addCustomerModalOpen);
  };

  const handleClientClick = ssn => async e => {
    e.preventDefault();

    const { client } = await api("/client/relationship", {
      ssn: formatSSN(ssn, { withDash: true })
    });

    setCustomerState(
      {
        isCompany: false,
        ssnOrg: client.id ? client.id.replace("-", "") : "",
        isNewCase: null,
        standaloneApplicationsChecked: [],
        needsNewDocumentation: false,
        kycCaseId: null,
        kycInfoLoading: false,
        overrideNewDocumentation: false,
        casesLoading: false,
        cases: null,
        selectedCaseId: null,
        selectedCaseIndex: -1,
        customerModalIsOpen: true
      },
      () => {
        setCustomerModalOpen(true);
        setSelectedCustomer(client);
        document.documentElement.style.overflow = "hidden";
      }
    );
  };

  const handleClientModalClose = () => {
    setCustomerState(
      {
        isCompany: false,
        ssnOrg: "",
        customerModalIsOpen: false
      },
      () => {
        setCustomerModalOpen(false);
        setSelectedCustomer(null);
      }
    );
  };

  const refreshLogs = async () => {
    fetchLogs(filters, page, order, orderBy);
    
    const { client } = await api("/client/relationship", {
      ssn: selectedCustomer.id
    });
    setSelectedCustomer(client);
  };

  const fetchLogs = async (filters, page, order, orderBy) => {
    setLoaded(false);
    const logs = await api("/partner/user/logs", {
      // ssn: ssn,
      filters,
      page: page || 0,
      order: order || "desc",
      orderBy: orderBy || "created",
      limit: rowsPerPage === -1 ? 100 : rowsPerPage
    });

    setCopyList(logs.batch);
    setUsers(logs.users);
    setClients(logs.clients);
    setNumCases(parseInt(logs.total));
    setLoaded(true);
  };

  // inital load and page/rowsPerPage changes useEffect
  useEffect(() => {
    fetchLogs(filters, page, order, orderBy);
  }, [filters, page, rowsPerPage, order, orderBy]);

  // Search useEffect
  useEffect(() => {
    if (mounted) {
      if (searchTimeout.current > 0) clearTimeout(searchTimeout.current);
      searchTimeout.current = setTimeout(() => {
        if (page > 0) setPage(0);
        fetchLogs(filters, page, searchString, order, orderBy);
      }, 2000);
    }
  }, [filters.search]);

  // CASES loading useEffect
  useEffect(() => {
    if (mounted) {
      setLoaded(!isLoadingCases);
    }
  }, [isLoadingCases]);

  return {
    isMobileOrTablet,
    user,
    partner,
    advisor,
    loaded,
    columns,
    copyList,
    users,
    clients,
    numCases,
    page,
    setPage,
    filters,
    setFilters,
    order,
    orderBy,
    rowsPerPage,
    selectedCustomer,
    customerModalOpen,
    addCustomerModalOpen,
    setRowsPerPage,
    setSelectedCustomer,
    handleChangePage,
    handleRequestSort,
    toggleAddCustomerModal,
    handleClientClick,
    handleClientModalClose,
    refreshLogs,
    caseFilterContext,
    setCustomerState,
    onWorkflowSwitched,
    nextStepOptions
  };
};

export default useAllActivityTable;
