export const initialState = {
  action: "add",
  personalNumber: "",
  roleId: 3,
  firstName: "",
  lastName: "",
  email: "",
  phoneHome: "",
  phoneMobile: "",
  customerNumber: "",
  futurCode: "",
  resellerId: 1,
  fetching: false,
  inputErrors: null,
  serverMessage: null
};

const addUserModalReducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return initialState;
    case "handleInput":
      return {
        ...state,
        [action.field]: action.payload,
        inputErrors: { ...state.inputErrors, [action.field]: null },
        serverMessage: null
      };
    case "lookingUp":
      return { ...state, [action.type]: action.payload };
    case "setUser": {
      // Here we have to take into account if the payload comes from the backend or frontend as the prop names and stucture can be different
      const payload = action.payload;

      const userData = {
        action: "update",
        personalNumber: payload.personal_number || payload.personalNumber,
        roleId: payload.role_id || payload.roleId || 3,
        firstName: payload.first_name || payload.firstName || "",
        lastName: payload.last_name || payload.lastName || "",
        customerNumber: payload.customer_number || payload.customerNumber || "",
        futurCode: payload.futur_code || payload.futurCode || "",
        resellerId: payload.reseller_id || payload.resellerId || "",
        email: payload.email || "",
        phoneHome: payload.phone_home || payload.phoneHome || "",
        phoneMobile: payload.phone_mobile || payload.phoneMobile || ""
      };

      return {
        ...state,
        ...userData
      };
    }
    case "fetching":
      return { ...state, [action.type]: action.payload };
    case "handleInputErrors":
      return { ...state, inputErrors: action.payload };
    case "handleServerError":
      return {
        ...state,
        fetching: false,
        serverMessage: { type: "error", text: action.payload }
      };
    case "handleServerSuccess":
      return {
        ...state,
        fetching: false,
        serverMessage: { type: "success", text: action.payload }
      };
    default:
      return state;
  }
};

export default addUserModalReducer;
