import { useState, useEffect } from "react";
// HELPERS
import api from "@helpers/api";
import i18n from "@helpers/i18n";
import { formatSSN } from "@helpers/utils";

// HOOKS
import useResponsive from "@hooks/useResponsive";

const useUsersTable = props => {
  const { user, options } = props;
  const [loaded, setLoaded] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("first_name");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [resellers, setResellers] = useState([]);
  const [copyList, setCopyList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [userStatusModalOpen, setUserStatusModalOpen] = useState(false);
  const [newUserAdded, setNewUserAdded] = useState(null);
  const [activeTab, setActiveTab] = useState("1");

  let columns = [];
  columns.push(
    {
      header: "",
      id: "menuButton",
      accessor: "menuButton",
      width: "2.5%",
      disablePadding: true,
      disableSort: true
    },
    {
      header: "Roll",
      accessor: "role_id",
      width: "10%"
    },
    {
      header: "Personnr",
      accessor: "personal_number",
      width: "12.5%"
    },
    {
      header: "Förnamn",
      accessor: "first_name",
      width: "7.5%"
    },
    {
      header: "Efternamn",
      accessor: "last_name",
      width: "10%"
    },
    {
      header: "Kontaktuppgifter",
      accessor: "email",
      width: "30%"
    },
    {
      header: "Ombud",
      accessor: "reseller_id",
      width: "20%"
    },
    {
      header: "Senast inloggad",
      accessor: "last_login",
      width: "10%"
    }
  );

  const roles = {
    administrator: 1,
    backoffice: 2,
    advisor: 3,
    sales: 4,
    assistant: 5
  };

  const tabs = [
    {
      key: "1",
      label: "Aktiva"
    },
    {
      key: "0",
      label: "Inaktiva"
    }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setPage(0);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleTabChange = newTab => {
    setCopyList(users.filter(user => user.is_active === newTab));
    setActiveTab(newTab);
  };

  const updateUsers = user => {
    // find existing index
    let index = users.findIndex(
      u =>
        (u.personal_number &&
          user.personalNumber &&
          u.personal_number === user.personalNumber) ||
        (u.personalNumber &&
          user.personalNumber &&
          u.personalNumber === user.personalNumber) ||
        (u.personal_number &&
          user.personal_number &&
          u.personal_number === user.personal_number)
    );

    const isNewUser = index === -1;
    // if not found, add to end (new user)
    if (isNewUser) {
      index = users.length;
      setNewUserAdded(user);

      setTimeout(() => {
        setNewUserAdded(null);
      }, 3000);
    }
    // update users with new user record
    users[index] = user;
    // sort alphabetically
    users.sort((a, b) =>
      (a.first_name || a.firstName).localeCompare(
        b.first_name || b.firstName,
        "sv",
        { sensitivity: "base" }
      )
    );

    return setCopyList(users.filter(user => user.is_active == activeTab));
  };

  const requestSearch = searched => {
    if (users) {
      if (page > 0) setPage(0);
      const searchString = searched.toLowerCase();
      setCopyList(
        users.filter(user => {
          const userRoleLabel = i18n(
            "user_roles",
            Object.keys(roles).find(role => roles[role] == user.role_id)
          );
          const userResellerLabel = resellers.find(
            reseller => reseller.reseller_id == user.reseller_id
          )?.company_name;
          const personalNumWithDash = formatSSN(user.personal_number, {
            withDash: true
          });

          return (
            user.is_active === activeTab &&
            ((user.first_name + " " + user.last_name)
              .toLowerCase()
              .includes(searchString) ||
              user.personal_number?.toLowerCase().includes(searchString) ||
              personalNumWithDash?.toLowerCase().includes(searchString) ||
              (userRoleLabel &&
                userRoleLabel.toLowerCase().includes(searchString)) ||
              (userResellerLabel &&
                userResellerLabel.toLowerCase().includes(searchString)))
          );
        })
      );
    }
  };

  const toggleAddUserModal = action => {
    if (action === "add") setSelectedUser(null);

    if (addUserModalOpen) {
      // disable/enable background content scrolling while modal is open
      document.documentElement.style.overflow = "auto";
    } else {
      document.documentElement.style.overflow = "hidden";
    }

    setAddUserModalOpen(!addUserModalOpen);
  };

  const toggleUserStatusModal = () => {
    if (userStatusModalOpen) {
      // disable/enable background content scrolling while modal is open
      document.documentElement.style.overflow = "auto";
    } else {
      document.documentElement.style.overflow = "hidden";
    }

    setUserStatusModalOpen(!userStatusModalOpen);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await api("partner/users", {}, {}, true);
      const { success, result } = response;
      if (success && result) {
        setUsers(result);
        setCopyList(result.filter(user => user.is_active == activeTab));
      }
      setLoaded(true);
    };

    const fetchResellers = async () => {
      const response = await api("/partner/resellers", {}, {}, true);
      const { success, result } = response;
      if (success && result) {
        setResellers(result);
      }
    };

    if (!loaded) {
      fetchUsers();
      fetchResellers();
    }
  }, [loaded]);

  return {
    loaded,
    mainUser: user,
    options,
    columns,
    roles,
    tabs,
    activeTab,
    copyList,
    resellers,
    page,
    order,
    orderBy,
    rowsPerPage,
    selectedUser,
    setPage,
    setSelectedUser,
    setRowsPerPage,
    handleTabChange,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    toggleAddUserModal,
    toggleUserStatusModal,
    updateUsers,
    addUserModalOpen,
    userStatusModalOpen,
    newUserAdded
  };
};

export default useUsersTable;
