import { isEmptyObject } from "@helpers/utils";

import Validator from "@classes/Validator";

const validateFields = fields => {
  let errors = {};
  for (const input in fields) {
    const value = fields[input];

    switch (input) {
      case "ssn":
      case "personalNumber":
        if (!Validator.validateSsn(value, { personsOnly: true })) {
          errors = {
            ...errors,
            [input]: "Var god ange ett giltigt personnummer."
          };
        }
        break;
      case "orgNumber":
        if (!Validator.validateSsn(value, { companiesOnly: true })) {
          errors = {
            ...errors,
            [input]: "Var god ange ett giltigt organisationsnummer."
          };
        }
        break;
      case "firstName":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            [input]: "Vänligen ange ett giltigt förnamn."
          };
        }
        break;
      case "lastName":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            [input]: "Vänligen ange ett giltigt efternamn."
          };
        }
        break;
      case "companyName":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            [input]: "Vänligen ange ett giltigt företagsnamn."
          };
        }
        break;
      case "country":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            [input]: "Vänligen ange ett land."
          };
        }
        break;
      case "street":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            [input]: "Vänligen ange en giltigt adress."
          };
        }
        break;
      case "zipCode":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            [input]: "Vänligen ange en giltigt postnummer."
          };
        }
        break;
      case "email":
        if (!Validator.validateEmail(value)) {
          errors = {
            ...errors,
            [input]: "Vänligen ange en giltigt mailadress."
          };
        }
        break;
      case "phoneNumber":
        if (!Validator.validatePhoneNum(value)) {
          errors = {
            ...errors,
            [input]: "Vänligen ange ett giltigt telefonnummer."
          };
        }
        break;
      case "futurCode":
        if (value && (isNaN(value) || value.length !== 5)) {
          errors = {
            ...errors,
            [input]: "Ogiltig framtida kod"
          };
        }
        break;
      default:
        break;
    }
  }

  if (!isEmptyObject(errors)) {
    return errors;
  }

  return false;
};

export default validateFields;
