import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  InputLabel,
  FormControl,
  FormHelperText,
  Typography,
  Select,
  MenuItem
} from "@mui/material";

const AdvisorSelect = props => {
  const { advisor, advisors, setAdvisor } = props;

  // if there's advisor set get its details from the list of advisors
  const currentAdvisor = advisor && advisors.find(
    a => a.personal_number === advisor.personal_number
  );

  const handleSelectChange = (e, v) => {
    e.preventDefault();
    setAdvisor(v.props.value);
  };

  return (
    <Box sx={{
      width: "fit-content",
      height: 40,
      mt: 1.5,
      mb: 1
    }}>
      <FormControl
        fullWidth
        sx={{
          alignItems: "flex-start",
          mb: 1
        }}
      >
        <InputLabel
          id="advisor-select-label"
          sx={{
            top: -6,
            // left: 6,
            "&.MuiInputLabel-shrink": {
              top: 0,
              left: 0
            }
          }}
        >
          Välj rådgivare
        </InputLabel>
        <Select
          size="small"
          labelId="advisor-select-label"
          id="advisor-select"
          name="advisor-select"
          label="Välj rådgivare"
          value={currentAdvisor ? currentAdvisor : ""}
          onChange={(e, v) => {
            handleSelectChange(e, v);
          }}
          MenuProps={{
            MenuListProps: {
              style: {
                width: "100%",
                textAlign: 'left'
              }
            }
          }}
          // renderValue={
          //   currentAdvisor
          //     ? () => (
          //       <Typography color="#637381" sx={{ height: 20, mt: "1px" }}>
          //         {currentAdvisor.first_name + " " + currentAdvisor.last_name}
          //       </Typography>
          //     )
          //     : () => null
          // }
          sx={{
            width: 250,
            textAlign: 'left',
            alignItems: "flex-start",
            "& fieldset": {
             borderColor: "#2e7d32"
            }
          }}
        >
          {advisors.map((advisor) => (
            <MenuItem
              key={advisor.personal_number}
              value={advisor}
              sx={{
                width: "100%",
                fontSize: "1rem",
                alignItems: "flex-start"
              }}
            >
              {advisor.first_name + " " + advisor.last_name}
            </MenuItem>
          ))}
        </Select>{/* <FormHelperText sx={{ color: "error.main" }}>{error}</FormHelperText> */}
      </FormControl>
    </Box>
  );
};

AdvisorSelect.propTypes = {
  advisors: PropTypes.array,
  advisor: PropTypes.object,
  setadvisor: PropTypes.func,
  error: PropTypes.string
};

export default AdvisorSelect;
