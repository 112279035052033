import { useState, useCallback, useReducer, useEffect } from "react";

import api from "@helpers/api";

import addUserModalReducer, { initialState } from "./addCompanyModalReducer";
import validateFields from "@helpers/formValidator";

const useAddCompanyModal = props => {
  const {
    company,
    updateCompanies,
    toggleModal
  } = props;

  const [state, dispatch] = useReducer(addUserModalReducer, {
    ...initialState
  });

  const hasId = company && company.org_number;
  const canSubmitForm = !!(
    state.companyName &&
    state.email
  );

  const toggle = () => {
    dispatch({
      type: "reset"
    });
    return toggleModal();
  };

  const isValidInput = (fieldName, value) => {
    if (
      fieldName === "is_partner"
    ) {
      return true;
    }
    
    return (
      value.length > 0 && (!state.inputErrors || !state?.inputErrors[fieldName])
    );
  };

  const handleInputChange = async e => {
    e.preventDefault();
    const key = e.target.name;
    let value = e.target.value;

    dispatch({
      type: "handleInput",
      field: key,
      payload: value
    });

    const formData = {
      ...state,
      [key]: value
    };

    const fieldsToValidate = { ...formData };
    // Exclude field that don't require validation
    delete fieldsToValidate.addressStreet;
    delete fieldsToValidate.addressCity;
    delete fieldsToValidate.addressZip;

    let inputErrors = validateFields(fieldsToValidate);

    return dispatch({
      type: "handleInputErrors",
      payload: inputErrors || {}
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    dispatch({
      type: "handleServerError",
      payload: null
    });

    const {
      action,
      orgNumber,
      isPartner,
      companyName,
      addressStreet,
      addressCity,
      addressZip,
      email
    } = state;

    const formData = {
      orgNumber,
      isPartner,
      companyName,
      addressStreet,
      addressCity,
      addressZip,
      email,
    };

    dispatch({
      type: "fetching",
      payload: true
    });

    try {
      const company = await api(`/partner/reseller/${action}`, formData);
      updateCompanies(company);
      toggle();
    } catch (err) {
      let errorMessage = err?.message || "Unknown error.";
      if (err?.message === "RESELLER_ALREADY_EXISTS") {
        errorMessage = "En användare med det företagsnumret finns redan.";
      }

      return dispatch({
        type: "handleServerError",
        payload: errorMessage || "Unknown error."
      });
    }
  };

  useEffect(() => {
    if (company) {
      dispatch({
        type: "setCompany",
        payload: company
      });
    }
  }, [company]);

  return {
    state,
    hasId,
    canSubmitForm,
    toggle,
    isValidInput,
    handleInputChange,
    handleSubmit
  };
};

export default useAddCompanyModal;
