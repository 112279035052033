export const initialState = {
  action: "add",
  orgNumber: "",
  companyName: "",
  addressStreet: "",
  addressZip: "",
  addressCity: "",
  email: "",
  isPartner: false,
  fetching: false,
  inputErrors: null,
  serverMessage: null
};

const addCompanyModalReducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return initialState;
    case "handleInput":
      return {
        ...state,
        [action.field]: action.payload,
        inputErrors: { ...state.inputErrors, [action.field]: null },
        serverMessage: null
      };
    case "lookingUp":
      return { ...state, [action.type]: action.payload };
    case "setCompany": {
      // Here we have to take into account if the payload comes from the backend or frontend as the prop names and stucture can be different
      const payload = action.payload;

      const companyData = {
        action: "update",
        orgNumber: payload.org_number || payload.orgNumber,
        companyName: payload.company_name || payload.companyName || "",
        addressStreet: payload.address_street || payload.addressStreet || "",
        addressCity: payload.address_city || payload.addressCity || "",
        addressZip: payload.address_zip || payload.addressZip || "",
        email: payload.notification_email || payload.email || "",
        isPartner: payload.is_partner === "1" || payload.isPartner === "1" ? true : false
      };

      return {
        ...state,
        ...companyData
      };
    }
    case "fetching":
      return { ...state, [action.type]: action.payload };
    case "handleInputErrors":
      return { ...state, inputErrors: action.payload };
    case "handleServerError":
      return {
        ...state,
        fetching: false,
        serverMessage: { type: "error", text: action.payload }
      };
    case "handleServerSuccess":
      return {
        ...state,
        fetching: false,
        serverMessage: { type: "success", text: action.payload }
      };
    default:
      return state;
  }
};

export default addCompanyModalReducer;
